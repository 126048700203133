import React, { useState } from 'react';
import Comercio from './comercio'
// import Listado from './listado'
import QRListado from './QRList'


function App() {

  const [Comercios, setComercios] = useState([]);


  const AddComercio = NewComercio => {
    console.log(NewComercio);
    setComercios(c => [...c, NewComercio])
  };

  return (
    <div>
      <h1>Generador de QR</h1>
      <Comercio AddComercio={AddComercio} />
      {/* <Listado Comercios={Comercios} /> */}
      <QRListado Comercios={Comercios} />
    </div>
  );
}

export default App;
