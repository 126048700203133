import React, { useState } from 'react';
function Comercio({ AddComercio }) {

    const [IdComercio, setIdComercio] = useState("");
    const [Nombre, setNombre] = useState("");

    return (
        <div>
            <input value={IdComercio} placeholder="ID Comercio" onChange={e => setIdComercio(e.target.value)}></input>
            <input value={Nombre} placeholder="Nombre" onChange={e => setNombre(e.target.value)}></input>
            <button onClick={e => AddComercio({IdComercio, Nombre})}>Agregar</button>
        </div>
    )
}

export default Comercio