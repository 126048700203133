import { QRCodeCanvas } from 'qrcode.react';


function QRListado({ Comercios }) {
    return (
        <div className='Container'>
            <div className='row'>
                <div className='col-md-12'>
                    {
                        Comercios.map((c, index) => {
                            return (
                                <div style={{ width: "492px" }}>
                                    <center style={{ width: "100vw" }}>
                                        <QRCodeCanvas size="492" key={index} value={"https://recotec.cl/comercio/?idComercio=" + c.IdComercio + "&nombreComercio=" + encodeURIComponent(c.Nombre)} />
                                    </center>
                                    <center style={
                                        {
                                            fontSize: "48px",
                                            marginLeft: "140%",
                                            fontWeight: "bolder",
                                        }   
                                    }>{c.IdComercio}</center>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default QRListado